




























































import {Component, Vue} from 'vue-property-decorator';
import Navbar from "@/components/Navbar.vue";
import DarkPatternDescriptionComponent from "@/components/DarkPatternDescriptionComponent.vue"; // @ is an alias to /src

@Component({
  components: {
    DarkPatternDescriptionComponent,
    Navbar
  },
})
export default class Home extends Vue {

  darkPatterns = [
    {
      title: "Trick Question",
      text: "Трик прашање се применува кога се пополнува некоја форма, така што се поставува прашање поставено со цел да дадеме одговор кој што не го сакаме. Најчесто не обрнуваме премногу време на прашањата, и доколку не ги прочитаме пазливо, може лесно да дадеме грешен одговор. Овој тип на патерн користи дупла негација за да не’ збуни. \n" +
          "\nНајчеста форма на ова е кога креираме профил или регистрираме. Познат пример е кога имаме 2 полиња за штиклирање кои што имаат спротивно значење. Првото поле може да има значење да не прифатиме да бидеме додадени на одредена мејлинг листа, а второто поле би било формулирано така што ние да очекува да има исто значење (да не прифатиме нешто), но всушност со штиклирање ние прифаќаме.\n" +
          "\nПример за користење на Трик Прашање е британската компанија за поштенски услуги, Royal Mail. При регистрација, на корисникот му се прикажува 2 разлилни полиња за избирање. Првото значи дека не сакаме да откажеме, а второт да се приклулиме. Полиња за избор речиси никогаш не се користат за да се откажеме или речеме НЕ за нешто, туку се користат за да се пријавиме за нешто.",
      demoUrl: "/trickquestion",
      imgUrl: "trick-question.jpg"
    },
    {
      title: "Потсмејување (Confirmshaming)",
      text: "Овој патерн се користи така што се исмејува и засрамува акцијата на корисникот доколку тој не сака да направи нешто. Пример за ова е Amazon кој што ги исмејува корисниците со реченици ‘No, I won’t want Unlimited One Day Delivery’ доколку тие одлучат дека не сакаат да го користат премиум сервисот за достава на Amazon, Amazon Prime. \n" +
          "\nНајчесто овој метод се користи за да се намами корисникот да се зачлени на некоја мејлинг листа, и за ова има безброј примери\n",
      demoUrl: "/confirmshaming",
      imgUrl: "confirmshaming.png",
    },
    {
      title: "Превенција за споредба на цена (Price Comparison Prevention)",
      text: "Како што кажува самото име, ова има цел да спречи споредба на цена на продукти со други конкурентни веб страни или пак со други продукти во истата страна.\n" +
          "\nНајчест пример за ова е користење на различни методи за одредување на цена. На прмер, еден продукт може да биде одреден како цена по тежина, а друг продукт да биде како цена по група од 3 продукти без да биде наведена нивната тежина.\n",
      demoUrl: "/pricecomparison",
      imgUrl: "price-comparison-prevention.png",
    },
    {
      title: "Скриено влези во кошница (Sneak into basket)",
      text: "Овој тип на Dark Pattern се појавува најчесто на e-commerce веб-страниците, каде што во нашата кошничка автоматски се додава некој продукт кој што сме немале намера да го купиме, и единствен начин да го тргнеме е преку рачно откажување од тој продукт.\n" +
          "\nОвој патерн е познат и како Negative Option Billing, и е нелегален во рамките на EU.\n",
      demoUrl: "/sneakintobasket",
      imgUrl: "sneak-into-basket.png",
    },
    {
      title: "Скриени цени (Hidden Costs)",
      text: "Овој патерн се појавува на e-commerce веб сајтови, каде што има скриени цени кои што не ни се покажуваат се додека не дојде момент за плаќање. При прелистување на сајтот нигде не се спомнати овие скриени цени, и со ова ние веќе трошиме време за да најдеме продукт кој што сакаме. Кога сме веќе кај страната за плаќање, додатни $3 ‘не се ништо’ за некој fee. \n" +
          "\nПример за ова е порано беше AirBnb, веб страница за изнајмување на апартмани и куќи од други корисници. При пребарување на слободните имоти нас ни е прикажа само цената од ноќ. Одкако ќе ги избереме датумите за нашиот престој, ни се покажуваат скриени цени кои што не ги очекувавме. Исто така овие цени се секогаш различни, така што многу е тешко да се предвиди колку би биле овие скриени цени доколку рачно не проверуваме за секој избран престој. Дополнително, цената за чистење (Cleaning Fee) ја немаат сите вклучено, односно негде е $0. За да се избегне ова, потребно е вкупната цена да биде прикажана секаде.\n" +
          "\nСепак, AirBnb веќе не го користи овој темен патерн, и сега целосната сума е секогаш прикажа при бребарување.\n",
      demoUrl: "/hiddencosts",
      imgUrl: "hidden-costs.png",
    },
    {
      title: "Лебарка мотел (Roach Motel)",
      text: "Некои веб сајтови се дизајнирани така што многу лесно не’ ставаат во ситуација од која што е тешко да се излезе. Пример за ова се страници кои што по автоматски ни нудат некоја субскрипција, а потоа ги кријат можностите за излегување или откажување на тоа. Дополнително, овие страници го прават и откажувањето од услугите кои што не ги сакаме тешки. На пример, доколку сме се зачлениле на некоја субскрипција, за да се откажеме не може едноставно да притиснеме дугме за откажување и треба да испратиме писмо по пошта.\n" +
          "\nСе разбира, како и некои други патерни, овој се користи и надвор во рамките на вебот. Многу познат пример во Америка е субскрипција на ‘LA Fitness’, голема мрежа на теретани во Калифорнија. Познати се по тоа што за да се зачлениш едноставно се пополнува лесна форма, но за да се откажеш од субскрипцијата потребно е да пополниш комплицирано барање и да го испратиш по пошта. Потоа, со задоцнување (а можеби и ќе се ‘изгуби’), фирмата ќе ви го процесира и ќе ве откаже од субскрипцијата.\n" +
          "\nДруг пример е следниот Rolling Stones бонус\n",
      imgUrl: "roach-motel.png",
    },
    {
      title: "Zucker на приватност (Privacy Zuckering)",
      text: "Во последните 5-10 години, при користење на речиси сите популарни сервиси кои што се бесплатни, ние сме продуктот. Најпознати компании кои што профитираат на овој начин се Google и Facebook, преку т.н Personalized Ads. Во маркетинг светот, голем проблем е рекламата да стигне до вистинскиот купувач. На пример, купувачи на луксузни часовници од брендови како Rolex или Patek Philippe не може да биде ‘било кој’, па затоа традиционалниот начин на рекламирање е во магазини кои што ги читаат луѓе со ниво на приход кој што би им дозволил такво нешто. Студент од Македонија кој што студира на ФИНКИ веројатно не би можел да си дозволи таков луксуз, додека тргувач на Walls Street веројатно би бил заинтересиран. Така фирми како Google и Facebook го решаваат овој проблем преку креирање на модели за нас според тоа што го пребаруваме и начинот на кој што го користиме интернетот, и ни ги даваат овие реклами.\n" +
          "\nНајчесто ние се согласуваме за споделување на нашите информации во Terms & Services при зачленување на сервисот. Кога се зачленуваме на социјални мрежи како Facebook, или пак на некоја веб страница како Amazon, ние автоматски прифаќаме да ги споделиме сите наши информации со компанијата и дозволуваме нивно споделување и користење за да ни дадат овие персонализирани реклами. \n" +
          "\nApple пред скоро време со најновата верзија на iOS 14 воведе барање за секоја апликација на нивниот App Store експлицитно да наведе кои информации ги собира од нас (при тоа да се наведе кои се анонимни, на пример Crash Reports). Ова се смета за чекор во правата насока за корисниците кои што сакаат приватност, меѓутоа доби негативни коментари од Facebook – кои што сметаат дека ова ќе им одмогне на малите бизниси кои што се обидуваат да се појават на пазарот.\n" +
          "\nВеб сајтот https://tosdr.org/ (Terms of Service Didn’t Read) е иницијатива која што се обидува да го реши проблемот на избегнување на читање на Terms of Service кои што ние слепо ги прифаќаме\n",
      imgUrl: "privacy-zuckering.png",
    },
    {
      title: "Скриени реклами (Disguised Ads)",
      text: "Ова се реклами кои што изгледаат исти како некој дел од веб сајтот, и често не сме сигурни што е реклама а што ‘навистина’ дел од веб сајтот. Целта е ние несакајќи да кликнеме на рекламата. Најпознат пример за ова е со ‘Download’ реклама, каде што во рекламата има текст кој што наликува на дугме за започнување на Download процес, додека пак вистинското дугме за Download е негде околу рекламата. Многу често кога ќе се кликне на грешното дугме, односно на рекламата, ние превземаме некој AdWare / Malware софтвер наместо тоа што навистина го сакаме.",
      imgUrl: "disguised-ads.jpg",
    },
    {
      title: "Мамење и префрлувањ (Bait and Switch)",
      text: "Овој метод се користи кога корисникот е измамен така што тој очекува да направи една акција, меѓутоа сосема поразлична акција се случува од очекуваното. Најпознат пример за ова е Microsoft со нивниот Windows 10 pop-up. Доколку корисникот кликне на ‘X’ дугмето, со цел да го исклучи прозорецот, тој ќе го прифати упдејтот на Windows 10. Ова придонесе голема контроверзија и се смета за измама, бидејќи историски тоа ‘X’ дугме има акција да го исклучи тековниот активен прозорец.\n" +
          "\nДруга форма на користење на ‘Bait and Switch’ е кога некоја страница рекламира одреден продукт по неверојатно ниска цена, меѓутоа продуктот веќе не е на залиха, и така нас ни се прикажуваат сличлни продукти. Кога веќе сме инвестирале време и гледаме слични продукти за ‘малку повеќе’ во цена, ние сме паднале на оваа мамка.\n" +
          "\nНа сликата подоле е прикажан екранот кој што Microsoft го прикажуваа на застарените Windows 7 и Windows 8 оперативни системи, каде што ‘X’ дугмето не значи ‘исклучи го прозорецов’.\n",
      imgUrl: "bait-and-switch.jpg",
    },
    {
      title: "Погрешно насочување (Misdirection)",
      text: "Генерално ова е техника која што се употребува во сите Dark Pattern-и бидејќи сите го користат овој трик на некој начин.",
    },
    {
      title: "Спам на Пријатели (Friend Spam)",
      text: "Овој патерн се случува кога од вас се бара дозвола која што е маскирана како да има некој позитивен исход, меѓутоа се случува Bait and Switch и се случува спам на вашите пријатели.\n" +
          "\nНајпознат пример е LinkedIn кој што беше приморан да плати $13 милиони долари ($10 од секој корисник) поради тужба во 2015.\n" +
          "\nПри креирање на профил на LinkedIn, од вас е побарано да дадете дозола за пристап до вашиот е-mail профил. Причината за ова е маскирана како ‘помош за да изградите цврста мрежа‘, меѓутоа вистинската причина е за да се испрати spam до сите ваши пријатели.\n" +
          "\nНа сликата е прикажана збунувачката страница на LinkedIn, каде што доколку се согласиме да дадеме пристап до нашата e-mail адреса, овој спам би се случил\n",
      imgUrl: "friend-spam.png",
    },
  ]
}
